<template>

<app-wrapper class="filter">

	<app-wrapper :content="true" class="filter-content">

		<div class="filter-bar-label" v-if="!window.is.mobile">Filter by</div>
		<div class="filter-bar-toggle" :class="{'is-active': isActive(1)}" v-on:click="onTypeClick(1)">News</div>
		<div class="filter-bar-toggle" :class="{'is-active': isActive(2)}" v-on:click="onTypeClick(2)">Cases</div>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

	data: function() {

		return {
			model: {
				changed: false,
				type: [1,2]
			}
		}

	},

	watch: {

		model: {

			deep: true,

			handler: function(n) {

				this.$emit('change', n)
					
			}

		}

	},

	methods: {

		isActive: function(value) {

			return this.$_.contains(this.model.type, value)

		},

		onTypeClick: function(value) {

			if (!this.changed) {

				this.changed = true
				this.model.type = [value]

			} else {

				if (this.$_.contains(this.model.type, value)) {

					this.model.type.splice(this.model.type.indexOf(value), 1)

				} else {

					this.model.type.push(value)

				}

			}

		}

	}

}

</script>

<style scoped>

.filter {
	border-bottom: 1px solid #e9e9e9;
}

.filter-content {
	display: flex;
}

.filter-bar {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.is-mobile .filter-content {
	padding: 0px;
}

.filter-bar-label {
	color: #1A2046;
	font-size: 15px;
	line-height: 64px;
	font-weight: 700;
	margin-right: 40px;
}

.filter-bar-toggle {
	font-size: 15px;
	font-weight: 500;
	user-select: none;
	line-height: 64px;
	color: #1A2046;
	padding: 0px 20px;
	margin-right: 20px;
	cursor: pointer;
}

.is-mobile .filter-bar-toggle {
	line-height: 48px;
	padding: 0px;
	margin: 0px;
	font-size: 14px;
	text-align: center;
	width: 33.3%;
}

.filter-bar-toggle.is-active:hover,
.filter-bar-toggle.is-active {
	border-bottom: 4px solid #FF2D07;
}

.is-mobile .filter-bar-toggle.is-active {
	border-bottom: 0px;
	font-weight: 700;
	color: #FF2D07;
}


</style>
